/* @flow strict */
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import ApolloClient, { gql, InMemoryCache } from "apollo-boost";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { ApolloProvider, Query } from "react-apollo";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { persistCache } from "apollo-cache-persist";

const init = async () => {
  const cache = new InMemoryCache();

  await persistCache({
    cache,
    storage: localStorage,
    key: "wuma-store",
  });

  const defaultState = {
    isLoggedIn: !!localStorage.getItem("authToken"),
    userIsMarketer: localStorage.getItem("userIsMarketer") === "true",
    userIsInfluencer: localStorage.getItem("userIsInfluencer") === "true",
    marketerId: localStorage.getItem("marketerId"),
    influencerId: localStorage.getItem("influencerId"),
    currentUserId: localStorage.getItem("currentUserId"),
  };

  const client = new ApolloClient({
    cache,
    uri: `${process.env.REACT_APP_BACKEND_URL}/graphql/`,
    fetchOPtions: {
      credentials: "include",
    },
    request: (operation) => {
      const token = localStorage.getItem("authToken") || "";
      operation.setContext({
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
    },
    clientState: {
      defaults: defaultState,
    },
  });

  client.onResetStore(() => {
    cache.writeData({ data: defaultState });
  });

  const IS_LOGGED_IN_QUERY = gql`
    query {
      isLoggedIn @client
      currentUserId @client
      userIsMarketer @client
      userIsInfluencer @client
      marketerId @client
      influencerId @client
    }
  `;

  ReactDOM.render(
    <ApolloProvider client={client}>
      <Query query={IS_LOGGED_IN_QUERY}>{({ data }) => <App data={data} />}</Query>
    </ApolloProvider>,
    document.getElementById("root")
  );
};

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
